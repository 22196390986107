import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import FormInput from "../FormInput/FormInput";
import CustomButton from "../Button/CustomButton";
import { ContentCopy, Delete, Phone } from "@mui/icons-material";
import styles from "../../styles";

const TrustedPersonPhoneItem = () => {
  return (
    <Card
      sx={{
        boxShadow: "none",

        bgcolor: "transparent",
        "& .MuiCardContent-root:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <CardContent sx={{ padding: "4px 0px" }}>
        <FormInput value={"+380777777777"} label={"Номер телефону"} fullWidth />

        <Box
          display={"flex"}
          alignItems={"center"}
          gap={"8px"}
          marginTop={"8px"}
        >
          <Button
            variant="outlined"
            fullWidth
            sx={{ padding: "8px", borderColor: "#4CAF50", borderRadius: "8px" }}
          >
            <Phone sx={{ fontSize: "22px", color: "#4CAF50" }} />
          </Button>

          <Button
            variant="outlined"
            fullWidth
            sx={{
              padding: "8px",
              borderColor: styles.colorPrimary,
              borderRadius: "8px",
            }}
          >
            <ContentCopy
              sx={{ fontSize: "22px", color: styles.colorPrimary }}
            />
          </Button>

          <Button
            variant="outlined"
            fullWidth
            sx={{ padding: "8px", borderColor: "#ED3737", borderRadius: "8px" }}
          >
            <Delete sx={{ fontSize: "22px", color: "#ED3737" }} />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrustedPersonPhoneItem;
