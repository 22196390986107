import React, { useEffect } from "react";
import "./App.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import generalStyles from "./styles";
import { Modal, ModalDialog, ModalClose } from "@mui/joy";
import AlarmModal from "./components/AlarmModal/AlarmModal";
import { Alarm, WarningAmberSharp } from "@mui/icons-material";
import CustomButton from "./components/Button/CustomButton";

const styles = {
  root: {
    minHeight: "100vh",
    backgroundColor: generalStyles.bgColor,
  },
  navAction: {
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    color: generalStyles.colorPrimary,
    borderRadius: "1rem",
    padding: "4px 0px",
  },
  activeNavAction: {
    backgroundColor: generalStyles.colorPrimary,
  },
};

const bgColorChange = (location: string) => {
  if (
    location.endsWith("/notifications") ||
    location.endsWith("/events-history")
  ) {
    return "#3E4462";
  }

  if (location.endsWith("/chat")) {
    return "linear-gradient(180deg, #567DBF 0%, #283A59 100%)";
  }

  return generalStyles.bgColor;
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState("/");
  const [showAlarm, setShowAlarm] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  return (
    <div
      className="App"
      style={{
        ...styles.root,
        background: bgColorChange(location.pathname),
      }}
    >
      <Container
        sx={{
          padding: "20px 12px",
        }}
        maxWidth="sm"
      >
        <Box sx={{ marginBottom: "60px" }} m={{}}>
          <Outlet />
        </Box>
      </Container>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "1rem",
          zIndex: 100,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          sx={{
            padding: "4px",
            backgroundColor: generalStyles.colorSecondary,
            "& .Mui-selected": {
              color: generalStyles.colorSecondary, // Default border color
            },
            gap: 2,
            borderStartStartRadius: "1rem",
            borderStartEndRadius: "1rem",
          }}
          // onChange={handleChange}
        >
          <BottomNavigationAction
            sx={
              value === "/"
                ? {
                    ...styles.navAction,
                    ...styles.activeNavAction,
                  }
                : styles.navAction
            }
            value="/"
            label="Об'єкти"
            icon={
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${
                    value === "/"
                      ? "nav-menu-objects-active.svg"
                      : "nav-menu-objects.svg"
                  }`}
                  alt="My Notifications"
                />
              </div>
            }
            onClick={() => navigate("/")}
          />
          <BottomNavigationAction
            sx={
              value === "/tech-reqs"
                ? {
                    ...styles.navAction,
                    ...styles.activeNavAction,
                  }
                : styles.navAction
            }
            value="/tech-reqs"
            label="Заявки"
            icon={
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${
                    value === "/tech-reqs"
                      ? "nav-menu-reqs-active.svg"
                      : "nav-menu-reqs.svg"
                  }`}
                  alt="My Notifications"
                />
              </div>
            }
            onClick={() => navigate("/tech-reqs")}
          />

          <BottomNavigationAction
            sx={
              value === "/chat"
                ? {
                    ...styles.navAction,
                    ...styles.activeNavAction,
                  }
                : styles.navAction
            }
            value="/chat"
            label="Чат"
            icon={
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${
                    value === "/chat"
                      ? "nav-menu-chat-active.svg"
                      : "nav-menu-chat.svg"
                  }`}
                  alt="Chat"
                />
              </div>
            }
            onClick={() => navigate("/chat")}
          />
          <BottomNavigationAction
            label="Профіль"
            sx={
              value === "/profile"
                ? {
                    ...styles.navAction,
                    ...styles.activeNavAction,
                  }
                : styles.navAction
            }
            value="/profile"
            icon={
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/${
                    value === "/profile"
                      ? "nav-menu-user-active.svg"
                      : "nav-menu-user.svg"
                  }`}
                  alt="My Notifications"
                />
              </div>
            }
            onClick={() => navigate("/profile")}
          />
        </BottomNavigation>
      </Paper>

      <Modal
        open={showAlarm}
        sx={{ padding: 0 }}
        onClose={() => setShowAlarm(!showAlarm)}
      >
        <ModalDialog
          color="neutral"
          sx={{
            borderRadius: "1rem",
            padding: 0,
            width: "100%",
            boxShadow: generalStyles.shadow,
            border: "none",
          }}
        >
          <AlarmModal />
        </ModalDialog>
      </Modal>
      <Button
        size="small"
        variant="contained"
        sx={{
          bgcolor: generalStyles.colorSecondary,
          position: "absolute",
          top: 0,
          right: 5,
        }}
        onClick={() => setShowAlarm(true)}
      >
        <WarningAmberSharp sx={{ color: "#D42F2F" }} />
      </Button>
    </div>
  );
}

export default App;
