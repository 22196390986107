import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import styles from "../../styles";

const TechnicianRequestComment = () => {
  return (
    <Card
      sx={{
        border: "2px",
        borderStyle: "solid",
        borderColor: "#A8B3E7",
        padding: "1rem",
        borderRadius: "1rem",
        background: "transparent",
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
      >
        <Typography
          sx={{
            color: styles.colorSecondary,
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "4px",
          }}
        >
          Юля Лукяненко
        </Typography>
        <Typography
          sx={{
            color: styles.colorSecondary,
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Заявка закрита координатором
        </Typography>
        <Typography
          sx={{
            color: styles.colorSecondary,
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          Тест
        </Typography>
        <Typography
          sx={{
            textAlign: "end",
            color: styles.colorSecondary,
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          13.05.2024 17:51:34
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TechnicianRequestComment;
