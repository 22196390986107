import { ChangeEvent, useCallback, useState } from "react";
import styles from "../styles";
import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  FormControl,
  FormGroup,
  Grid,
  ImageList,
  ImageListItem,
  InputAdornment,
  Typography,
} from "@mui/material";
import FormInput from "../components/FormInput/FormInput";
import { LocationOn } from "@mui/icons-material";
import CustomButton from "../components/Button/CustomButton";
import { Textarea } from "@mui/joy";
import CoordinatessPickerMap from "../components/CoordinatessPickerMap/CoordinatessPickerMap";
import { DbProtectedObjectPhoto } from "../types/ProtectedObjectPhoto";
import { convert, resizeAndConvert } from "../utils/photo";

interface FormFields {
  object_name: string;
  full_address: string;
  description: string;
  latitude: string;
  longitude: string;
  note: string;
}

interface FormErrors {
  isObjectNameError: boolean;
  isFullAddressError: boolean;
  isInvalidCoordinates: boolean;
}

interface OptionType {
  inputValue?: string;
  title: string;
}

const formFieldsErrorsDefaultValues: FormFields = {
  object_name: "",
  full_address: "",
  description: "",
  latitude: "",
  longitude: "",
  note: "",
};

const formErrorsDefaultValues: FormErrors = {
  isObjectNameError: false,
  isFullAddressError: false,
  isInvalidCoordinates: false,
};

const filter = createFilterOptions<OptionType>();

const itemData = [
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 1",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 2",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 3",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 4",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 1",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 2",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 3",
  },
  {
    img: "https://via.placeholder.com/150", // Replace with your image URLs
    title: "Image 4",
  },
];

const maxMBytes = 500;

const CreatingObject = () => {
  const [options, setOptions] = useState<OptionType[]>([
    { title: "Option 1" },
    { title: "Option 2" },
    { title: "Option 3" },
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPreparingPhoto, setIsPreparingPhoto] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<FormFields>(
    formFieldsErrorsDefaultValues
  );
  const [formErrors, setFormErrors] = useState<FormErrors>(
    formErrorsDefaultValues
  );

  const [value, setValue] = useState<OptionType[]>([]);

  const [photos, setPhotos] = useState<string[]>([]);
  const [img, setImg] = useState<string>("");

  // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) {
  //     const file = event.target.files[0];
  //     if (file) {
  //       console.log(file.name);
  //       // Handle the uploaded file here
  //     }
  //   }
  // };

  const onObjectNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields((prev) => ({ ...prev, object_name: event.target.value }));
    setFormErrors((prev) => ({ ...prev, isObjectNameError: false }));
  };

  const onFullAddressHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields((prev) => ({
      ...prev,
      full_address: event.target.value,
    }));
    setFormErrors((prev) => ({ ...prev, isFullAddressError: false }));
  };

  const onCoordinatesChangeHandler = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        setFormErrors((prev) => ({
          ...prev,
          isInvalidCoordinates: false,
        }));

        setFormFields((prev) => ({
          ...prev,
          latitude: event.latLng!.lat().toString(),
          longitude: event.latLng!.lng().toString(),
        }));
      }
    },
    []
  );

  const filterOptions = (options: OptionType[], params: any) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new option if the input value doesn't match any existing option
    if (params.inputValue !== "") {
      filtered.push({
        title: params.inputValue,
      } as OptionType);
    }

    return filtered;
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: Array<OptionType | string>
  ) => {
    const lastItem = newValue[newValue.length - 1];

    if (lastItem && typeof lastItem === "string") {
      setValue((prev) => [...prev, { title: lastItem }]);
    } else if (
      lastItem &&
      typeof lastItem !== "string" &&
      lastItem.inputValue
    ) {
      setValue((prev) => [...prev, { title: lastItem.inputValue || "" }]);
    } else {
      setValue([...(newValue as OptionType[])]);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      // setFormErrors((prev) => ({ ...prev, is: false }));

      const filesArr = Object.values(event.target.files);
      const filesForResizing: File[] = [];
      const normalFiles: File[] = [];

      filesArr.forEach((f) => {
        if (f.size > 1048.576 * maxMBytes) {
          filesForResizing.push(f);
        } else {
          normalFiles.push(f);
        }
      });

      let photosResized: string[];
      let normalPhotos: string[];

      try {
        setIsPreparingPhoto(true);
        photosResized = await Promise.all(
          filesForResizing.map((file) => resizeAndConvert(file))
        );

        normalPhotos = await Promise.all(
          normalFiles.map((file) => convert(file))
        );

        // setFormFields((prev) => ({
        //   ...prev,
        //   tech_act_photos: [...photosResized, ...normalPhotos].map((ph) => ({
        //     tech_act_id: "",
        //     photo: ph,
        //   })),
        // }));

        setPhotos([...photosResized, ...normalPhotos]);
      } catch (error: any) {
        // errorToast(error.message);
        console.error(error.message);
      } finally {
        setIsPreparingPhoto(false);
      }
    }
  };

  return (
    <Grid
      container
      sx={{
        color: styles.colorSecondary,
      }}
    >
      <Grid item component="section" xs={12}>
        <Typography
          align="center"
          gutterBottom
          sx={{
            color: styles.colorSecondary,
            fontSize: "26px",
            fontWeight: 600,
          }}
        >
          Створення нового об'єкту
        </Typography>
        <Box component="form" noValidate>
          <Box
            sx={{
              bgcolor: styles.colorSecondary,
              padding: "24px 16px",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <FormInput
              required
              id="outlined-required"
              label="Назва"
              placeholder="Ведіть назву об'єкта"
              helperText={
                formErrors.isObjectNameError && "Некоректна назва об'єкту"
              }
              error={formErrors.isObjectNameError}
              value={formFields.object_name}
              onChange={onObjectNameHandler}
            />

            <FormInput
              required
              label="Адреса"
              placeholder="Ведіть адресу об'єкта"
              error={formErrors.isObjectNameError}
              helperText={
                formErrors.isObjectNameError && "Некоректна адреса об'єкту"
              }
              value={formFields.full_address}
              onChange={onFullAddressHandler}
            />
            <FormGroup>
              <FormInput
                required
                label="Координати"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                disabled
                placeholder="Ведіть координати об'єкта"
                error={formErrors.isInvalidCoordinates}
                value={`${formFields.latitude} ${formFields.longitude}`}
              />
              <Box height={"20vh"} borderRadius={"12px"}>
                <CoordinatessPickerMap
                  marker={{
                    lat: Number(formFields.latitude),
                    lng: Number(formFields.longitude),
                  }}
                  onMapClick={onCoordinatesChangeHandler}
                />
              </Box>
            </FormGroup>

            <Box>
              <Box
                sx={{
                  paddingY: "12px",
                  borderRadius: "8px",
                  backgroundColor: "#3E4462",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  textAlign: "center",
                  color: styles.colorSecondary,
                  position: "relative",
                  marginBottom: "4px",
                }}
                onClick={() => document.getElementById("file-upload")?.click()}
              >
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/object-file-input-icon.svg`}
                    alt="file input icon"
                  />
                </span>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 500, fontSize: "14px" }}
                >
                  Щоб додати зображення
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  натисність сюди
                </Typography>
              </Box>

              <ImageList
                sx={{ flexWrap: "nowrap", overflowX: "auto", margin: 0 }}
                cols={6}
                gap={3}
              >
                {photos.map((photo) => (
                  <ImageListItem key={photo} sx={{ minWidth: 50 }}>
                    <img
                      src={`${photo}`}
                      srcSet={`${photo}`}
                      alt={"photo"}
                      loading="lazy"
                      style={{ borderRadius: "4px" }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>

            <Autocomplete
              fullWidth
              multiple
              freeSolo
              id="multiple-limit-tags"
              value={value}
              options={options}
              onChange={handleChange}
              filterOptions={filterOptions}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.title
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    sx={{
                      background: styles.bgColor,
                      color: styles.colorSecondary,
                    }}
                    label={option.title}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => <FormInput {...params} label="Теги" />}
            />

            <FormGroup sx={{ marginTop: "-12px" }}>
              <Typography
                component={"label"}
                fontSize={"12px"}
                fontWeight={400}
                color={"rgba(0, 0, 0, 0.6)"}
              >
                Опис
              </Typography>

              <FormControl fullWidth>
                <Textarea
                  sx={{
                    borderRadius: "8px",
                    fontSize: "18px",
                    fontWeight: 500,
                    border: "1px",
                    bgcolor: styles.colorSecondary,
                    borderStyle: "solid",
                    borderColor: styles.colorPrimary,
                  }}
                  minRows={4}
                  placeholder="Ведіть опис об'єкта"
                />
              </FormControl>
            </FormGroup>
            <FormInput
              label="Коментар"
              placeholder="Ведіть коментар для об'єкта"
            />
          </Box>

          <CustomButton
            variant="outlined"
            fullWidth
            sx={{ marginBottom: "16px", marginTop: "24px" }}
          >
            Зберегти
          </CustomButton>

          <CustomButton variant="contained" fullWidth>
            Додати об'єкт
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreatingObject;
