import {
  Autocomplete,
  Box,
  Card,
  Chip,
  createFilterOptions,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "../styles";
import { CalendarToday, Edit } from "@mui/icons-material";
import FormInput from "../components/FormInput/FormInput";
import CustomButton from "../components/Button/CustomButton";
import ObjectMap from "../components/ObjectMap/ObjectMap";
import { Download } from "@mui/icons-material";
import { Textarea } from "@mui/joy";
import DeviceCard from "../components/DeviceCard/DeviceCard";

const itemData: {
  img: string;
  title: string;
}[] = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
  },
];

const DateCard = ({ label, date }: { label: string; date: string }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CalendarToday sx={{ marginRight: "8px" }} />
      <Box>
        <Typography fontWeight={500} fontSize={"18px"}>
          {label}
        </Typography>
        <Typography fontWeight={500} fontSize={"18px"}>
          {date}
        </Typography>
      </Box>
    </Box>
  );
};

interface OptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<OptionType>();

const Object = () => {
  const { objectID } = useParams();
  const [selectedImg, setSelectedImg] = useState<{
    img: string;
    title: string;
  }>(itemData[0]);

  const [options, setOptions] = useState<OptionType[]>([
    { title: "Option 1" },
    { title: "Option 2" },
    { title: "Option 3" },
  ]);

  const [value, setValue] = useState<OptionType[]>(options);

  const filterOptions = (options: OptionType[], params: any) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new option if the input value doesn't match any existing option
    if (params.inputValue !== "") {
      filtered.push({
        title: params.inputValue,
      } as OptionType);
    }

    return filtered;
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: Array<OptionType | string>
  ) => {
    const lastItem = newValue[newValue.length - 1];

    if (lastItem && typeof lastItem === "string") {
      setValue((prev) => [...prev, { title: lastItem }]);
    } else if (
      lastItem &&
      typeof lastItem !== "string" &&
      lastItem.inputValue
    ) {
      setValue((prev) => [...prev, { title: lastItem.inputValue || "" }]);
    } else {
      setValue([...(newValue as OptionType[])]);
    }
  };

  return (
    <Grid
      container
      sx={{
        color: styles.colorSecondary,
      }}
    >
      <Grid item component="section" xs={12}>
        <Box sx={{ marginBottom: "1rem" }}>
          <Box
            sx={{
              position: "relative",
              backgroundImage: `url(${selectedImg.img}?w=164&h=164&fit=crop&auto=format)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "250px",
              borderRadius: "8px",
              marginBottom: "8px",
            }}
          >
            <Chip
              label={`${
                itemData.findIndex((item) => item.img === selectedImg.img) + 1
              } з ${itemData.length}`}
              size="small"
              sx={{
                position: "absolute",
                bottom: 5,
                left: 5,
                background: "rgba(0, 0, 0, 0.70)",
                fontSize: "12px",
                lineHeight: "12px",
                fontWeight: 600,
                color: "inherit",
              }}
            />
          </Box>
          <ImageList
            sx={{ flexWrap: "nowrap", overflowX: "auto", margin: 0 }}
            cols={6}
            gap={3}
          >
            {itemData.map((item) => (
              <ImageListItem
                onClick={() => setSelectedImg(item)}
                key={item.img}
                sx={{
                  minWidth: 50,

                  cursor: "pointer",
                  border:
                    item.img === selectedImg.img
                      ? `1px ${styles.colorPrimary}`
                      : "none",
                }}
              >
                <img
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    borderRadius: "4px",
                    // border: "1px",
                    // borderStyle: "solid",
                    border:
                      item.img === selectedImg.img
                        ? `1px solid ${styles.colorSecondary}`
                        : "none",
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>

        <Box
          sx={{
            boxShadow: styles.shadow,

            bgcolor: "#3E4462",
            padding: "8px",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            fullWidth
            multiple
            freeSolo
            id="multiple-limit-tags"
            value={value}
            options={options}
            onChange={handleChange}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.title
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  sx={{
                    background: styles.bgColor,
                    color: styles.colorSecondary,
                  }}
                  label={option.title}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <FormInput
                {...params}
                label="Теги"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: styles.colorSecondary, // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: styles.colorSecondary, // Border color when hovered
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: styles.colorSecondary, // Border color when focused
                    },
                  },

                  "& .MuiInputLabel-root": {
                    color: styles.colorSecondary,
                  },
                }}
              />
            )}
          />
        </Box>

        <Box component="form" noValidate>
          <Box
            sx={{
              boxShadow: styles.shadow,
              bgcolor: styles.colorSecondary,
              padding: "24px 1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <FormInput
              required
              id="outlined-required"
              error={false}
              label="Назва"
              placeholder="Ведіть назву об'єкта"
              //   helperText="Incorrect entry."
              value={`Об'єкт [${objectID}]`}
            />
            <FormInput
              required
              error={false}
              label="Адреса"
              placeholder="Ведіть адресу об'єкта"
              value={`[${objectID}] street`}
            />

            <FormInput
              label="Номер"
              placeholder="Ведіть номер об'єкта"
              value={objectID && objectID[3]}
            />

            <FormGroup sx={{ marginTop: "-12px" }}>
              <Typography
                component={"label"}
                fontSize={"12px"}
                fontWeight={400}
                color={"rgba(0, 0, 0, 0.6)"}
              >
                Опис
              </Typography>

              <FormControl fullWidth>
                <Textarea
                  sx={{
                    borderRadius: "8px",
                    fontSize: "18px",
                    fontWeight: 500,
                    border: "1px",
                    bgcolor: styles.colorSecondary,
                    borderStyle: "solid",
                    borderColor: styles.colorPrimary,
                  }}
                  minRows={4}
                  placeholder="Ведіть опис об'єкта"
                  value={`Опис для [${objectID}]`}
                />
              </FormControl>
            </FormGroup>

            <FormGroup sx={{ marginTop: "-12px" }}>
              <Typography
                component={"label"}
                fontSize={"12px"}
                fontWeight={400}
                color={"rgba(0, 0, 0, 0.6)"}
              >
                Статус
              </Typography>

              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  placeholder="Виберіть статус об'єкта"
                  sx={{
                    borderRadius: "8px",
                    fontSize: "18px",
                    fontWeight: 500,
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: styles.colorPrimary,
                  }}
                  value={10}
                >
                  <MenuItem value={10}>Активний</MenuItem>
                  <MenuItem value={20}>Призупинений</MenuItem>
                  <MenuItem value={30}>Відключений</MenuItem>
                  <MenuItem value={40}>Потенційний</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Box>

          <Card
            sx={{
              boxShadow: styles.shadow,
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              bgcolor: styles.colorSecondary,
              marginTop: "1rem",
              borderRadius: "1rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <span style={{ marginRight: "12px" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/manager-icon.svg`}
                  alt="ІСТОРІЯ"
                />
              </span>
              <Box>
                <Typography
                  fontWeight={500}
                  fontSize={"14px"}
                  lineHeight="normal"
                >
                  Менеджер
                </Typography>
                <Typography fontWeight={500} fontSize={"18px"}>
                  Валерій Микуленко
                </Typography>
              </Box>
            </Box>
            <IconButton>
              <Edit sx={{ color: styles.colorPrimary }} />
            </IconButton>
          </Card>

          <Card
            sx={{
              marginTop: "1rem",
              padding: "1rem",
              borderRadius: "1rem",
              bgcolor: styles.colorSecondary,
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              boxShadow: styles.shadow,
            }}
          >
            <Box>
              <Typography fontWeight={400} fontSize={"18px"}>
                Номер договору:{" "}
                <Typography component={"span"} fontWeight={600}>
                  230901-01
                </Typography>
              </Typography>
              <Typography fontWeight={400} fontSize={"18px"}>
                Абонентська плата:{" "}
                <Typography component={"span"} fontWeight={600}>
                  1000 грн
                </Typography>
              </Typography>
            </Box>
            <IconButton sx={{ color: styles.colorPrimary }}>
              <Download />
            </IconButton>
          </Card>

          <Card
            sx={{
              marginTop: "1rem",
              padding: "1rem",
              borderRadius: "1rem",
              bgcolor: styles.colorSecondary,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              boxShadow: styles.shadow,
            }}
          >
            <DateCard label="Дата підключення" date="25.12.2023" />
            <DateCard label="Дата відключення" date="25.01.2024" />
          </Card>

          <Card
            sx={{
              marginTop: "1rem",
              borderRadius: "1rem",
              bgcolor: styles.colorSecondary,
              boxShadow: styles.shadow,
              height: "150px",
              marginBottom: "1rem",
            }}
          >
            <ObjectMap />
          </Card>

          <DeviceCard />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <Link to={"trusted-persons"} style={{ textDecoration: "none" }}>
              <CustomButton sx={{ paddingY: 0 }} fullWidth>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/contact-persons-button-img.png`}
                      alt="Відповідальні особи"
                      style={{ height: "80px" }}
                    />
                  </span>
                  {/* Adjust image size and path */}
                  <Typography fontSize={"20px"} fontWeight={800}>
                    Відповідальні особи
                  </Typography>
                </Box>
              </CustomButton>
            </Link>

            <Link to={"events-history"} style={{ textDecoration: "none" }}>
              <CustomButton sx={{ paddingY: 0 }} fullWidth>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/history-button-img.png`}
                      alt="ІСТОРІЯ"
                      style={{ height: "80px" }}
                    />
                  </span>
                  {/* Adjust image size and path */}
                  <Typography fontSize={"32px"} fontWeight={800}>
                    ІСТОРІЯ
                  </Typography>
                </Box>
              </CustomButton>
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Object;
