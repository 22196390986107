import {
  Person,
  AccessTime,
  DirectionsCar,
  Event,
  NumbersSharp,
  LocationOn,
} from "@mui/icons-material";
import {
  Card,
  Box,
  CardContent,
  Typography,
  Chip,
  IconButton,
} from "@mui/material";
import React, { FC } from "react";
import TechnicianRequests, {
  TechRequests,
} from "../../pages/TechnicianRequests";
import styles from "../../styles";

type Props = {
  techRequest: TechRequests;
};

const TechnicianRequestCard: FC<Props> = ({ techRequest }) => {
  return (
    <Card
      sx={{
        borderRadius: "1rem",
        backgroundColor: styles.colorSecondary,
        padding: "1rem",
        boxShadow: styles.shadow,
        color: styles.colorPrimary,
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "1rem",
          }}
          gutterBottom
        >
          {techRequest.reason}
        </Typography>

        <Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <Person sx={{ mr: 1, fontSize: "22px" }} /> {techRequest.technician}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <Event sx={{ mr: 1, fontSize: "22px" }} />{" "}
            {new Date(techRequest.date).toDateString()}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <AccessTime sx={{ mr: 1, fontSize: "22px" }} /> 10:00 - 18:00
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <NumbersSharp sx={{ mr: 1, fontSize: "22px" }} />
            {techRequest.objectNumber}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "18px",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <LocationOn sx={{ mr: 1, fontSize: "22px" }} />
            {techRequest.address}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="1rem"
        >
          <Chip
            label="12.05.2024 18:00"
            sx={{
              backgroundColor: "#ED3737",
              color: styles.colorSecondary,
              padding: "10px",
              borderRadius: "0.5rem",
              fontSize: "1rem",
              fontWeight: 400,
            }}
          />

          {techRequest.withDeparture && (
            <DirectionsCar
              sx={{ fontSize: "2rem", color: styles.colorPrimary }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TechnicianRequestCard;
