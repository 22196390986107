import React, { FC } from "react";
import { TestChatMessage } from "../../pages/Chat";
import { Avatar, Card, CardContent, Typography, Box } from "@mui/material";
import { Grid } from "@mui/material";
import styles from "../../styles";

type Props = {
  message: TestChatMessage;
};

const ChatMessage: FC<Props> = ({ message }) => {
  const mainContent = () => {
    return (
      <Box
        display={"flex"}
        gap={"8px"}
        sx={{
          "& .MuiCardContent-root:last-child": {
            paddingBottom: "8px",
          },
        }}
      >
        {message.type === "manager" && (
          <Avatar
            alt="Manager"
            src={`${process.env.PUBLIC_URL}/assets/manager-img.png`}
            sx={{ width: 28, height: 28 }}
          />
        )}
        <Card
          sx={
            message.type === "manager"
              ? {
                  background: styles.colorSecondary,
                  color: styles.colorPrimary,
                  borderRadius: "8px",
                  borderTopLeftRadius: "0",
                }
              : {
                  background: "#2D3339",
                  color: styles.colorSecondary,
                  borderRadius: "8px",
                  borderTopRightRadius: "0",
                }
          }
        >
          <CardContent sx={{ padding: "8px" }}>
            {message.type === "manager" && (
              <Typography
                fontSize={"12px"}
                fontWeight={500}
                lineHeight={"normal"}
                marginBottom={"4px"}
              >
                {message.sender_name}
              </Typography>
            )}
            {message.photo && (
              <Box sx={{ marginX: "-8px" }}>
                <img
                  src={message.photo}
                  alt="My Notifications"
                  width={"100%"}
                />
              </Box>
            )}

            <Typography
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"normal"}
              marginBottom={"4px"}
            >
              {message.text}
            </Typography>
            <Typography
              fontSize={"10px"}
              fontWeight={500}
              lineHeight={"normal"}
              sx={{
                opacity: 0.5,
                textAlign: message.type === "me" ? "end" : "start",
              }}
            >
              {new Date(message.date).toLocaleTimeString()}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const renderStrategy = () => {
    if (message.type === "me") {
      return (
        <>
          <Grid item xs={3} />
          <Grid item xs={9}>
            {mainContent()}
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={9}>
          {mainContent()}
        </Grid>
        <Grid item xs={3} />
      </>
    );
  };

  return (
    <Grid container xs={12}>
      {renderStrategy()}
    </Grid>
  );
};

export default ChatMessage;
