import {
  AppBar,
  Box,
  FormControl,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  ToggleButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import styles from "../styles";
import { CalendarToday, Edit, EventOutlined } from "@mui/icons-material";
import FormInput from "../components/FormInput/FormInput";
import UserProfileCard from "../components/UserProfileCard/UserProfileCard";

import {
  LocalizationProvider,
  DatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
// import ukLocale from "date-fns/locale/uk";

const Profile = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <Grid
      container
      sx={{
        color: styles.colorSecondary,
      }}
    >
      <Grid item component="section" xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none", padding: 0 }}
        >
          <Toolbar sx={{ backgroundColor: "transparent", padding: 0 }}>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Профіль
            </Typography>
            <ToggleButton
              color="warning"
              value="check"
              selected={false}
              onChange={() => {}}
            >
              <Edit />
            </ToggleButton>
          </Toolbar>
        </AppBar>
      </Grid>

      <Grid item component="section" xs={12}>
        <UserProfileCard />
      </Grid>

      <Grid item xs={12}>
        <Box
          component="form"
          noValidate
          sx={{
            marginTop: "1rem",
            padding: "24px 16px",
            bgcolor: styles.colorSecondary,
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            // alignItems: "center",
            gap: "1rem",
            boxShadow: styles.shadow,
          }}
        >
          <FormInput fullWidth label={"Прізвище"} value={"Павлюк"} required />
          <FormInput fullWidth label={"Ім'я"} value={"Роман"} required />
          <FormInput
            fullWidth
            label={"По батькові"}
            value={"Васильович"}
            required
          />

          <FormGroup sx={{ marginTop: "-12px" }}>
            <Typography
              component={"label"}
              fontSize={"12px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.6)"}
            >
              Стать
            </Typography>

            <FormControl fullWidth required>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                placeholder="Виберіть роль"
                sx={{
                  borderRadius: "8px",
                  fontSize: "18px",
                  fontWeight: 500,
                  border: "1px",
                  borderStyle: "solid",
                  borderColor: styles.colorPrimary,
                }}
                value={10}
              >
                <MenuItem value={10}>Чоловіча</MenuItem>
                <MenuItem value={20}>Жіноча</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <FormInput
            required
            fullWidth
            label="Номер телефону"
            placeholder="Ведіть номер телефону"
            value={"+380637777777"}
          />

          <FormInput
            fullWidth
            label="Додатковий номер телефону"
            placeholder="Ведіть номер телефону"
            value={"+380637777777"}
          />

          <FormInput
            type="email"
            fullWidth
            label={"E-mail"}
            value={"test@gmail.com"}
          />

          <FormInput fullWidth label={"Кодове слово"} value={"TEST"} />

          <FormInput fullWidth label={"PIN"} value={"1111"} />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              // views={["day", "month", "year"]}
              label="Дата народження"
              format="dd MMMM, yyyy"
              slotProps={{
                textField: {
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventOutlined
                          sx={{ color: styles.colorPrimary, opacity: 0.8 }}
                        />
                      </InputAdornment>
                    ),
                  },
                },
              }}
              value={selectedDate}
              onChange={handleDateChange}
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  fontSize: "18px",
                  fontWeight: 500,
                  border: "1px",
                  borderColor: styles.colorPrimary,
                  color: styles.colorPrimary,
                },

                "& .MuiPaper-root.MuiDialog-paper": {
                  background: "#3E4462",
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: styles.colorPrimary, // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: styles.colorPrimary, // Border color when hovered
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: styles.colorPrimary, // Border color when focused
                  },
                  "& .MuiInputLabel-root": {
                    fontFamily: '"Roboto", sans-serif', // Change the font family of the label
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    fontFamily: '"Roboto", sans-serif', // Change the font family when focused
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Profile;
