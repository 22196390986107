import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Modal,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowForward, Search, Tune } from "@mui/icons-material";
import React, { useState } from "react";
import ProtectedObjectCard from "../components/ProtectedObjectCard/ProtectedObjectCard";
import styles from "../styles";
import { Link } from "react-router-dom";
import CustomButton from "../components/Button/CustomButton";
import ObjectsFilter from "../components/ObjectsFilter/ObjectsFilter";

const objsIDs = ["obj1", "obj2", "obj3"];

export interface FiltersGroup {
  searchQuery: string;
  tags: { title: string }[];
}

const filtersGroupDefaultValue: FiltersGroup = {
  searchQuery: "",
  tags: [],
};

const Home = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const [filtersGroup, setFiltersGroup] = useState<FiltersGroup>(
    filtersGroupDefaultValue
  );

  const onSearchQueryInputHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFiltersGroup((prev) => ({ ...prev, searchQuery: event.target.value }));
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event?.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenFilters(open);
    };

  return (
    <>
      <Grid
        container
        sx={{
          color: styles.colorSecondary,
        }}
      >
        <Grid item component="section" xs={12}>
          <Box
            sx={{
              padding: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={`${process.env.PUBLIC_URL}/assets/profile-default-img.png`}
                sx={{ width: 28, height: 28 }}
              />
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  lineHeight: "normal",
                  m: 0,
                }}
                gutterBottom
              >
                Вітаємо, Романе!
              </Typography>
            </Box>

            <Link to={"notifications"} style={{ textDecoration: "none" }}>
              <IconButton edge="start" color="inherit" aria-label="back">
                <Icon>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/notifications.svg`}
                    alt="My Notifications"
                  />
                </Icon>
              </IconButton>
            </Link>
          </Box>
          <TextField
            variant="outlined"
            placeholder="Назва, номер чи адреса об'єкта"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleDrawer(true)}>
                    <Tune sx={{ color: styles.colorPrimary }} />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#F4F4F4", // Customize the background color
                borderRadius: "20px", // Make the corners rounded
                fontSize: "14px", // Adjust font size if necessaryW
                fontWeight: 600,
                boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
              },
            }}
            fullWidth
            value={filtersGroup.searchQuery}
            onChange={onSearchQueryInputHandler}
          />
        </Grid>

        <Grid
          container
          component="section"
          sx={{ marginTop: "16px", color: styles.colorSecondary }}
          columnSpacing={2}
        >
          <Grid item xs={7}>
            <Box
              sx={{
                backgroundColor: "#8EB0CB", // Light blue background
                borderRadius: "16px", // Rounded corners
                padding: "18px", // Internal padding
                boxShadow: styles.shadow,
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                Ваш баланс
              </Typography>
              <Typography
                sx={{
                  fontSize: "40px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                34 125 ₴
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Button
              sx={{
                color: styles.colorSecondary,
                backgroundColor: "#65D180", // Green background
                borderRadius: "16px", // Rounded corners
                fontSize: "18px",
                textTransform: "none",
                textAlign: "start",
                lineHeight: "normal",
                padding: "18px", // Internal padding

                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                boxShadow: styles.shadow,
              }}
              fullWidth
            >
              Бажаєте поповнити?
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                <ArrowForward sx={{ fontSize: "40px" }} />
              </Box>
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: "16px",
            color: styles.colorSecondary,
            width: "100%",
          }}
        >
          {/* Add Object Button */}
          <Link to={"/creating-object"} style={{ textDecoration: "none" }}>
            <CustomButton variant="outlined" fullWidth>
              <Add />
              <Typography fontSize={"18px"}>Додати об'єкт</Typography>
            </CustomButton>
          </Link>
        </Box>

        <Grid
          container
          component="section"
          sx={{ marginTop: "16px", color: styles.colorSecondary }}
          spacing={2}
        >
          {objsIDs.map((id) => (
            <Grid item xs={6} key={id}>
              <Link to={`objects/${id}`} style={{ textDecoration: "none" }}>
                <ProtectedObjectCard />
              </Link>
            </Grid>
          ))}
          <Grid item xs={6}>
            <ProtectedObjectCard withOverlay />
          </Grid>
        </Grid>
      </Grid>
      <SwipeableDrawer
        anchor="bottom"
        open={openFilters}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        ModalProps={{
          BackdropProps: {
            sx: {
              backgroundColor: "rgba(0, 0, 0rgb(11 13 14 / 25%), 0.2)", // Customize color and opacity
              backdropFilter: "blur(8px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          },
        }}
      >
        <ObjectsFilter
          filtersGroup={filtersGroup}
          changeFiltersGroup={setFiltersGroup}
        />
      </SwipeableDrawer>
    </>
  );
};

export default Home;
