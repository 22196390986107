import { HomeWork, LocationOn, Shield } from "@mui/icons-material";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  Chip,
  SvgIcon,
} from "@mui/material";
import React, { FC } from "react";
import styles from "../../styles";

type Props = {
  withOverlay?: boolean;
};

const ProtectedObjectCard: FC<Props> = ({ withOverlay = false }) => {
  return (
    <Box
      sx={{
        position: "relative",
        boxShadow: styles.shadow,
        borderRadius: "8px",
      }}
    >
      <Card
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          borderRadius: "8px",
          "& .MuiCardContent-root:last-child": {
            paddingBottom: "8px",
          },
        }}
      >
        <CardMedia
          component="img"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxXNUsMc2ZGSm2usz2RTYLffM6Juju7vY5hA&s"
          alt="Building"
        />
        <CardContent
          sx={{
            padding: "8px",
            backgroundColor: styles.colorSecondary,
            color: styles.colorPrimary,
          }}
        >
          <Box display="flex">
            <SvgIcon>
              <path
                d="M5.875 8.125H7.125V10H9V6.875H10.25L6.5 3.75L2.75 6.875H4V10H5.875V8.125ZM6.5 0.625L12.125 3.125V6.875C12.125 10.3438 9.725 13.5875 6.5 14.375C3.275 13.5875 0.875 10.3438 0.875 6.875V3.125L6.5 0.625Z"
                fill="#2C2C2C"
              />
            </SvgIcon>
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Об'єкт 1
            </Typography>
          </Box>
          <Box display="flex" gap={"1px"}>
            <SvgIcon>
              <path
                d="M8.25 11.9421L3.75 10.3671L0.7815 11.5146C0.5895 11.5861 0.41125 11.5654 0.24675 11.4524C0.08225 11.3394 0 11.1804 0 10.9754V1.80287C0 1.66887 0.0325001 1.54662 0.0975001 1.43612C0.1625 1.32562 0.257 1.24887 0.381 1.20587L3.75 0.0576172L8.25 1.63262L11.2185 0.485117C11.4105 0.413617 11.5887 0.427117 11.7533 0.525617C11.9177 0.624117 12 0.771117 12 0.966617V10.2546C12 10.3981 11.9602 10.5226 11.8807 10.6281C11.8017 10.7341 11.6953 10.8086 11.5613 10.8516L8.25 11.9421ZM7.875 11.0271V2.25212L4.125 0.943367V9.71837L7.875 11.0271ZM8.625 11.0271L11.25 10.1624V1.27487L8.625 2.25287V11.0271ZM0.75 10.7249L3.375 9.71837V0.943367L0.75 1.83737V10.7249Z"
                fill="#2C2C2C"
              />
            </SvgIcon>
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Вул. Шевченка 5
            </Typography>
          </Box>
          <Box display="flex" gap={"1px"}>
            <SvgIcon>
              <path
                d="M3.375 11.125H8.625V10.375H3.375V11.125ZM3.375 8.125H8.625V7.375H3.375V8.125ZM1.962 13.75C1.6165 13.75 1.32825 13.6345 1.09725 13.4035C0.86625 13.1725 0.7505 12.8843 0.75 12.5387V1.46125C0.75 1.11625 0.86575 0.82825 1.09725 0.59725C1.32875 0.36625 1.617 0.2505 1.962 0.25H7.875L11.25 3.625V12.5387C11.25 12.8837 11.1345 13.172 10.9035 13.4035C10.6725 13.635 10.384 13.7505 10.038 13.75H1.962ZM7.5 4V1H1.962C1.8465 1 1.7405 1.048 1.644 1.144C1.5475 1.24 1.4995 1.34575 1.5 1.46125V12.5387C1.5 12.6538 1.548 12.7595 1.644 12.856C1.74 12.9525 1.84575 13.0005 1.96125 13H10.0387C10.1538 13 10.2595 12.952 10.356 12.856C10.4525 12.76 10.5005 12.654 10.5 12.538V4H7.5Z"
                fill="#2C2C2C"
              />
            </SvgIcon>
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Офісне приміщення площею 125 м2
            </Typography>
          </Box>
          {
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              <Chip
                sx={{
                  background: styles.bgColor,
                  color: styles.colorSecondary,
                }}
                label="Tag 1"
              />

              <Chip
                sx={{
                  background: "#AA5DD9",
                  color: styles.colorSecondary,
                }}
                label="Tag 1"
              />

              <Chip
                sx={{
                  background: "#D42F2F",
                  color: styles.colorSecondary,
                }}
                label="Security"
              />
            </Box>
          }
        </CardContent>
      </Card>

      {withOverlay && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: "blur(4px) saturate(70%)",
            backgroundColor: "rgba(8, 26, 95, 0.677)",
            filter: "drop-shadow(0 2px 3px rgba(13, 40, 143, 0.105))",
            color: styles.colorSecondary,
            display: "flex",
            borderRadius: "8px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px",
          }}
        >
          <Chip
            label="New"
            size="small"
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              background: "#55994A",
              fontSize: "14px",
              fontWeight: 500,
              color: "inherit",
            }}
          />
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", fontWeight: 600, marginBottom: "8px" }}
          >
            Об'єкт 15
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", fontWeight: 600, textAlign: "center" }}
          >
            Перебуває на верифікації оператора
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProtectedObjectCard;
