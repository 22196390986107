import { TextField, TextFieldProps } from "@mui/material";
import React, { FC } from "react";
import styles from "../../styles";

const FormInput: FC<TextFieldProps> = ({ ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        sx: {
          borderRadius: "8px",
          fontSize: "18px",
          fontWeight: 500,
          border: "1px",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: styles.colorPrimary, // Default border color
          },
          "&:hover fieldset": {
            borderColor: styles.colorPrimary, // Border color when hovered
          },
          "&.Mui-focused fieldset": {
            borderColor: styles.colorPrimary, // Border color when focused
          },
          "& .MuiInputLabel-root": {
            fontFamily: '"Roboto", sans-serif', // Change the font family of the label
          },
          "& .MuiInputLabel-root.Mui-focused": {
            fontFamily: '"Roboto", sans-serif', // Change the font family when focused
          },
        },
        ...props.sx,
      }}
    />
  );
};

export default FormInput;
