import React, { useCallback, useState } from "react";

import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

type MapInstance = google.maps.Map | null;

const ObjectMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB5TbqX82LVWlGda6VKNn5EqaU_I0LkCgw",
  });

  const [map, setMap] = useState<MapInstance>(null);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: 50.34577594303541, lng: 30.46026981364137 }}
      zoom={15}
      //    onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={{
          lat: 50.34577594303541,
          lng: 30.46026981364137,
        }}
        title="Test"
        // onClick={() => handleMarkerObjClick(po)}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default ObjectMap;
