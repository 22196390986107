import imageCompression from "browser-image-compression";

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const convert = async (file: File) => {
  let result;
  try {
    result = await toBase64(file);

    //   setFormErrors((currErrors) => ({
    //     ...currErrors,
    //     photoError: false,
    //   }));
  } catch (error: any) {
    //   errorToast(error.message);
    console.error(error.message);
  }

  return result as string;
};

export const resizeAndConvert = async (file: File) => {
  console.log(`originalFile size ${file.size / 1024} KB`);

  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  let fileBase64;

  try {
    const compressedFile = await imageCompression(file, options);

    console.log(`compressedFile size ${compressedFile.size / 1024} KB`); // smaller than maxSizeMB

    fileBase64 = await convert(compressedFile);
  } catch (error: any) {
    console.error(error.message);
  }

  return fileBase64 as string;
};
