import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "../../styles";
import {
  Edit,
  Delete,
  MonetizationOn,
  Phone,
  X,
  Check,
  Close,
} from "@mui/icons-material";
import FormInput from "../FormInput/FormInput";
import TrustedPersonPhoneItem from "../TrustedPersonPhoneItem/TrustedPersonPhoneItem";
import CustomButton from "../Button/CustomButton";

const TrustedPersonCard = () => {
  return (
    <Accordion
      sx={{
        boxShadow: styles.shadow,
        bgcolor: styles.colorSecondary,
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          bgcolor: styles.colorSecondary,
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
        }}
      >
        <Card
          sx={{
            width: "100%",
            "& .MuiCardContent-root:last-child": {
              marginBottom: 0,
            },
          }}
        >
          <CardContent
            sx={{
              boxShadow: styles.shadow,
              bgcolor: styles.colorSecondary,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <span style={{ marginRight: "12px" }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/manager-icon.svg`}
                    alt="ІСТОРІЯ"
                  />
                </span>
                <Typography
                  fontWeight={500}
                  fontSize={"18px"}
                  lineHeight={"normal"}
                >
                  Павлюк Роман Васильович
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <IconButton>
                  <Edit sx={{ fontSize: "22px", color: styles.colorPrimary }} />
                </IconButton>
                <IconButton>
                  <Delete sx={{ fontSize: "22px", color: "#ED3737" }} />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <Typography
                fontWeight={400}
                fontSize={"18px"}
                lineHeight="normal"
                textAlign={"center"}
                marginTop={"8px"}
              >
                +380 (98) 968 21 45
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography
                fontWeight={400}
                fontSize={"16px"}
                lineHeight="normal"
                textAlign={"center"}
                marginTop={"8px"}
              >
                Кодове слово: TEST
              </Typography>
              <MonetizationOn sx={{ fontSize: "16px", color: "#4CAF50" }} />
            </Box>
          </CardContent>
        </Card>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <FormInput fullWidth value={"Павлюк"} label={"Прізвище"} />
          <FormInput fullWidth value={"Роман"} label={"Ім'я"} />
          <FormInput fullWidth value={"Васильович"} label={"По батькові"} />

          <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
            <Typography
              marginBottom={"-12px"}
              fontWeight={500}
              fontSize={"14px"}
              lineHeight={"normal"}
            >
              Телефони
            </Typography>
            <TrustedPersonPhoneItem />
            <TrustedPersonPhoneItem />

            <FormGroup>
              <FormInput label={"Новий номер телефону"} />
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={1}
                marginTop={"8px"}
              >
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px",
                    borderColor: "#4CAF50",
                    borderRadius: "8px",
                  }}
                >
                  <Check sx={{ fontSize: "22px", color: "#4CAF50" }} />
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px",
                    borderColor: "#ED3737",
                    borderRadius: "8px",
                  }}
                >
                  <Close sx={{ fontSize: "22px", color: "#ED3737" }} />
                </Button>
              </Box>
            </FormGroup>
          </Box>

          <FormInput fullWidth value={"TEST"} label={"Кодове слово"} />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
          >
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  sx={{
                    "&.MuiCheckbox-root": {
                      borderRadius: "10px", // Make the checkbox rounded
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "32px", // Adjust the size as needed
                      borderRadius: "50%", // Ensure the icon itself is rounded
                    },
                  }}
                />
              }
              label="Фінансово відповідальна особа"
              labelPlacement="start"
              sx={{
                margin: 0,
                "& .MuiTypography-root": {
                  fontSize: "18px",
                  fontWeight: 500,
                },
              }}
            />

            <CustomButton
              fullWidth
              sx={{
                bgcolor: styles.bgColor,
                color: styles.colorSecondary,
                borderRadius: "8px",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Посилання на рахунок
            </CustomButton>
          </Box>

          <FormGroup>
            <Typography
              component={"label"}
              fontSize={"12px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.6)"}
            >
              Метод оповіщення (об’єкт)
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  fontSize: "18px",
                  fontWeight: 500,
                  border: "1px",
                  borderStyle: "solid",
                  borderColor: styles.colorPrimary,
                }}
              >
                <MenuItem value={10}>Дзвінок та повідомлення</MenuItem>
                <MenuItem value={20}>Дзвінок</MenuItem>
                <MenuItem value={30}>Повідомлення</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Box>

        {/* <FormInput fullWidth value={"Васильович"} /> */}
      </AccordionDetails>
    </Accordion>
  );
};

export default TrustedPersonCard;
