import React from "react";
import {
  AppBar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import styles from "../styles";
import { Check, KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput/FormInput";
import CustomButton from "../components/Button/CustomButton";

const CreatingTrustedPerson = () => {
  const navigate = useNavigate();
  return (
    <Grid container sx={{ color: styles.colorSecondary }}>
      <Grid item xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none", padding: 0 }}
        >
          <Toolbar sx={{ backgroundColor: "transparent", padding: 0 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Створення відповідальної особи
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          noValidate
          sx={{
            marginTop: "1rem",
            padding: "1rem",
            bgcolor: styles.colorSecondary,
            borderRadius: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            boxShadow: styles.shadow,
          }}
        >
          <FormInput
            fullWidth
            label="Номер телефону"
            placeholder="Ведіть номер телефону особи"
          />
          <CustomButton
            fullWidth
            sx={{
              width: "max-content",
              bgcolor: styles.bgColor,
              color: styles.colorSecondary,
            }}
          >
            <Check />
          </CustomButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          noValidate
          sx={{
            marginTop: "1rem",
            padding: "1rem",
            bgcolor: styles.colorSecondary,
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            // alignItems: "center",
            gap: "1rem",
            boxShadow: styles.shadow,
          }}
        >
          <FormInput fullWidth label={"Прізвище"} />
          <FormInput fullWidth label={"Ім'я"} />
          <FormInput fullWidth label={"По батькові"} />

          <FormGroup>
            <Typography
              component={"label"}
              fontSize={"12px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.6)"}
            >
              Роль
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                placeholder="Виберіть роль"
                sx={{
                  borderRadius: "8px",
                  fontSize: "18px",
                  fontWeight: 500,
                  border: "1px",
                  borderStyle: "solid",
                  borderColor: styles.colorPrimary,
                }}
              >
                <MenuItem value={10}>Адміністратор</MenuItem>
                <MenuItem value={20}>Менеджер</MenuItem>
                <MenuItem value={30}>Суперадміністратор</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <Box sx={{ color: styles.colorPrimary }}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  sx={{
                    "&.MuiCheckbox-root": {
                      borderRadius: "10px", // Make the checkbox rounded
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "32px", // Adjust the size as needed
                      borderRadius: "50%", // Ensure the icon itself is rounded
                    },
                  }}
                />
              }
              label="Finance"
              labelPlacement="start"
              sx={{
                margin: 0,
                "& .MuiTypography-root": {
                  fontSize: "18px",
                  fontWeight: 500,
                },
              }}
            />
          </Box>
          <CustomButton
            fullWidth
            sx={{
              bgcolor: styles.bgColor,
              color: styles.colorSecondary,
            }}
          >
            Створити
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreatingTrustedPerson;
