import { Button, ButtonProps } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { FC } from "react";
import styles from "../../styles";

const stylesForOutlined: SxProps = {
  borderRadius: "16px",
  borderColor: styles.colorSecondary,
  color: styles.colorSecondary,
  paddingY: 2,
  fontSize: "22px",
  fontStyle: "normal",
  textTransform: "none",
  fontWeight: 500,
  lineHeight: "normal",
  boxShadow: styles.shadow,
};

const stylesForContained: SxProps = {
  borderRadius: "16px",
  borderColor: styles.colorSecondary,
  color: styles.colorPrimary,
  bgcolor: styles.colorSecondary,
  paddingY: 2,
  fontSize: "22px",
  fontStyle: "normal",
  textTransform: "none",
  fontWeight: 500,
  lineHeight: "normal",
  boxShadow: styles.shadow,
};
const CustomButton: FC<ButtonProps> = ({ children, sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={
        props.variant === "outlined"
          ? { ...stylesForOutlined, ...sx }
          : { ...stylesForContained, ...sx }
      }
    >
      {children}
    </Button>
  );
};

export default CustomButton;
