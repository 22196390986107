import React, { useState, useCallback, FC } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerProps,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Alert } from "@mui/material";

type Props = {
  marker: google.maps.LatLngLiteral | null;
  onMapClick: (event: google.maps.MapMouseEvent) => void;
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 50.45025720263965,
  lng: 30.523868497559434,
};

const CoordinatessPickerMap: FC<Props> = ({ marker, onMapClick }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB5TbqX82LVWlGda6VKNn5EqaU_I0LkCgw",
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  return (
    <>
      {loadError && <Alert severity="error">Loading map Error!</Alert>}
      {!loadError && isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          //    onLoad={onLoad}
          onClick={onMapClick}
          onUnmount={onUnmount}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
      )}
    </>
  );
};

export default CoordinatessPickerMap;
