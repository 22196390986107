import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  Circle,
  PowerSettingsNew,
  SimCard,
  ImportantDevices,
  LockOpen,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";
import styles from "../../styles";
import CustomButton from "../Button/CustomButton";
import { useState } from "react";

const zones = [
  "2 СМК вхідних дверей",
  "9 Об’єм хола 1 пв. 1 р-н",
  "9 Об’єм хола 1 пв. 1 р-н",
  "2 СМК вхідних дверей",
  "9 Об’єм хола 1 пв. 1 р-н",
  "2 СМК вхідних дверей",
  "9 Об’єм хола 1 пв. 1 р-н",
  "2 СМК вхідних дверей",
  "9 Об’єм хола 1 пв. 1 р-н",
];

const DeviceCard = () => {
  const [showAllZones, setShowAllZones] = useState(false);

  return (
    <Card sx={{ borderRadius: "1rem" }}>
      <CardContent
        sx={{
          backgroundColor: styles.colorSecondary,
          color: styles.colorPrimary,
          boxShadow: styles.shadow,
          padding: "1rem",
        }}
      >
        {/* Header Section */}
        <Box display="flex" alignItems="center" marginBottom="1rem">
          <ImportantDevices sx={{ fontSize: 40, marginRight: "8px" }} />

          <Box>
            <Typography
              component={"header"}
              sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "normal" }}
            >
              Прилад{" "}
              <Typography
                component="span"
                sx={{ fontSize: "18px", fontWeight: 700, lineHeight: "normal" }}
              >
                Ajax 7590
              </Typography>
            </Typography>

            <Box display="flex" alignItems="center" gap="12px">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                <Circle sx={{ fontSize: "12px", color: "#4CAF50" }} />
                Online
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                <LockOpen
                  sx={{ fontSize: "12px", color: styles.colorPrimary }}
                />
                Open
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                <PowerSettingsNew sx={{ fontSize: "12px", color: "#ED3737" }} />
                Off
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Info Section */}
        <Grid container marginBottom="1rem" xs={12}>
          <Grid item xs={4}>
            <Box marginBottom="8px">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                Номер
              </Typography>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "normal" }}
              >
                7590
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                Time-out
              </Typography>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "normal" }}
              >
                10800
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box marginBottom="8px">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                Тип
              </Typography>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "normal" }}
              >
                U-PROX
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(44, 44, 44, 0.70)",
                }}
              >
                Sim 1
              </Typography>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "normal" }}
              >
                <SimCard sx={{ fontSize: 14, mr: 0.5 }} />
                +380 (98) 894 21 87
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Zones Section */}
        <Accordion
          expanded={showAllZones}
          sx={{
            backgroundColor: "#3E4462",
            borderRadius: "12px",
            padding: "12px 16px",
            marginX: "-8px",

            "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
              marginX: 0,
            },
          }}
        >
          <AccordionSummary
            sx={{
              padding: 0,
              "& .MuiAccordionSummary-content": {
                flexDirection: "column",
              },

              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "normal",
                color: styles.colorSecondary,
                marginBottom: "12px",
              }}
            >
              Наявні зони
            </Typography>

            <TextField
              variant="outlined"
              placeholder="Пошук по зонах"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),

                sx: {
                  backgroundColor: styles.colorSecondary, // Customize the background color
                  borderRadius: "12px", // Make the corners rounded
                  fontSize: "18px", // Adjust font size if necessaryW
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginBottom: "12px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "5px",
                },
              }}
              fullWidth
            />

            {!showAllZones && (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="8px"
                  marginBottom="1rem"
                >
                  <Box
                    sx={{
                      bgcolor: styles.bgColor,
                      borderRadius: "8px",
                      padding: "6px 12px",

                      color: styles.colorSecondary,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      2 СМК вхідних дверей
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: styles.bgColor,
                      borderRadius: "8px",
                      padding: "6px 12px",

                      color: styles.colorSecondary,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      9 Об’єм хола 1 пв. 1 р-н
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: styles.bgColor,
                      borderRadius: "8px",
                      padding: "6px 12px",

                      color: styles.colorSecondary,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      9 Об’єм хола 1 пв. 1 р-н
                    </Typography>
                  </Box>
                </Box>
                <CustomButton
                  variant="outlined"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                  onClick={() => setShowAllZones(true)}
                  fullWidth
                >
                  Переглянути всі наявні зони
                </CustomButton>
              </>
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              style={{
                maxHeight: "50vh",
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  2 СМК вхідних дверей
                </Typography>
              </Box>

              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>

              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: styles.bgColor,
                  borderRadius: "8px",
                  padding: "6px 12px",

                  color: styles.colorSecondary,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  9 Об’єм хола 1 пв. 1 р-н
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setShowAllZones(false)}
              sx={{ textAlign: "center" }}
            >
              <KeyboardArrowUp
                sx={{
                  fontSize: "2rem",
                  textAlign: "center",
                  color: styles.colorSecondary,
                }}
              />
            </IconButton>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
