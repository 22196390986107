import { Card, CardContent, Typography } from "@mui/material";
import React, { FC } from "react";
import styles from "../../styles";
import { AccessTime, Check, NotInterested, X } from "@mui/icons-material";

type Props = {
  bgColor: "red" | "green" | "grey";
};

const NotificationItem: FC<Props> = ({ bgColor = "green" }) => {
  const makeIconColor = (): "success" | "secondary" | "danger" => {
    if (bgColor === "green") {
      return "success";
    }

    if (bgColor === "red") {
      return "danger";
    }

    if (bgColor === "grey") {
      return "secondary";
    }

    return "success";
  };
  const makeColorScheme = () => {
    const colors = {
      bgColor: "rgba(76, 175, 80, 0.20)",
      borderColor: "#4CAF50",
      iconColor: makeIconColor(),
    };

    if (bgColor === "green") {
      colors.bgColor = "rgba(76, 175, 80, 0.20)";
      colors.borderColor = "#4CAF50";
      colors.iconColor = makeIconColor();
    }

    if (bgColor === "red") {
      colors.bgColor = "rgba(255, 31, 31, 0.15)";
      colors.borderColor = "#FF1F1F";
      colors.iconColor = makeIconColor();
    }

    if (bgColor === "grey") {
      colors.bgColor = "rgba(255, 254, 247, 0.30)";
      colors.borderColor = "rgba(255, 254, 247, 0.30)";
      colors.iconColor = makeIconColor();
    }

    return colors;
  };

  const colorScheme = makeColorScheme();
  return (
    <Card
      sx={{
        position: "relative",
        color: styles.colorSecondary,
        backgroundColor: colorScheme.bgColor,
        border: "1px",
        borderStyle: "solid",
        borderColor: colorScheme.borderColor,
        borderRadius: "8px",
      }}
    >
      <CardContent sx={{ padding: "16px" }}>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          mb={"4px"}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "normal" }}
          >
            Об’єкт 23
          </Typography>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "normal" }}
          >
            02.07.2024 18:40
            {bgColor === "green" && (
              <Check
                sx={{
                  color: colorScheme.borderColor,
                  marginTop: "-8px",
                  marginBottom: "8px",
                  marginRight: "-8px",
                  marginLeft: "8px",
                  fontSize: "18px",
                }}
              />
            )}
            {bgColor === "red" && (
              <NotInterested
                sx={{
                  color: colorScheme.borderColor,
                  marginTop: "-8px",
                  marginBottom: "8px",
                  marginRight: "-8px",
                  marginLeft: "8px",
                  fontSize: "18px",
                }}
              />
            )}
            {bgColor === "grey" && (
              <AccessTime
                sx={{
                  color: colorScheme.borderColor,
                  marginTop: "-8px",
                  marginBottom: "8px",
                  marginRight: "-8px",
                  marginLeft: "8px",
                  fontSize: "18px",
                }}
              />
            )}
          </Typography>
        </Typography>

        <Typography
          sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "normal" }}
        >
          Ваша заявку на додавання об’єкту була відхилена
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NotificationItem;
