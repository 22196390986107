import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Paper,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import generalStyles from "../styles";
import { useNavigate } from "react-router-dom";

const styles = {
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#567DBF",
  },
  content: {
    textAlign: "center",
    color: generalStyles.colorSecondary,
    width: "100%",
    padding: "0 16px", // Ensure padding on mobile screens
  },
  textField: {
    border: "1px rgba(255, 254, 247, 0.50)",
    borderRadius: "8px",
    marginBottom: "16px",
    "& .MuiInputBase-input": {
      padding: "12px",
      color: generalStyles.colorSecondary,
    },
    boxShadow: generalStyles.shadow,
  },
  button: {
    backgroundColor: generalStyles.colorSecondary,
    color: generalStyles.bgColor,
    padding: "12px 0",
    borderRadius: "8px",
    textTransform: "none",
    boxShadow: generalStyles.shadow,
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
};

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const onPhoneNumberInputHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.target.value);
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <div style={styles.root}>
      <Grid container>
        <Grid item xs={12} sx={styles.content}>
          <Box component={"form"} onSubmit={onSubmitHandler}>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontFamily: '"Outfit", sans-serif',
                fontWeight: 700,
              }}
            >
              A1
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: '"Outfit", sans-serif',
                fontWeight: 700,
              }}
              gutterBottom
            >
              SECURITY GROUP
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontWeight: 500,
                fontSize: "1rem",
                marginTop: "2rem",
              }}
              gutterBottom
            >
              Для входу введіть Ваш номер телефону
            </Typography>
            <TextField
              sx={styles.textField}
              variant="outlined"
              fullWidth
              placeholder="+00 000 00 000"
              value={phoneNumber}
              onChange={onPhoneNumberInputHandler}
            />
            <Button
              sx={styles.button}
              variant="contained"
              type="submit"
              fullWidth
            >
              Підтвердити
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
