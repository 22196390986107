import { AppBar, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import styles from "../styles";
import { Add, KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import TrustedPersonCard from "../components/TrustedPersonCard/TrustedPersonCard";

const TrustedPersons = () => {
  const navigate = useNavigate();

  return (
    <Grid container sx={{ color: styles.colorSecondary }}>
      <Grid item xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none", padding: 0 }}
        >
          <Toolbar sx={{ backgroundColor: "transparent", padding: 0 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Відповідальні особи
            </Typography>
            <IconButton color="inherit" onClick={() => navigate("creating")}>
              <Add />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid container rowGap={"1rem"}>
        <Grid item xs={12}>
          <TrustedPersonCard />
        </Grid>
        <Grid item xs={12}>
          <TrustedPersonCard />
        </Grid>
        <Grid item xs={12}>
          <TrustedPersonCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrustedPersons;
