import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "../styles";
import TechnicianRequestCard from "../components/TechnicianRequestCard/TechnicianRequestCard";
import { Link } from "react-router-dom";

export interface TechRequests {
  reason: string;
  technician: string;
  date: Date;
  withDeparture: boolean;
  address: string;
  objectNumber: string;
}

const techReqs: TechRequests[] = [
  {
    reason: "Підключення додаткових датчиків",
    technician: "Олександр Гірич",
    date: new Date(),
    withDeparture: true,
    address: "c. Тарасівка, вул. Богдана Хмельницького, 11в",
    objectNumber: "7777",
  },
  {
    reason: "Перенесення датчика",
    technician: "Олександр Гірич",
    date: new Date(),
    withDeparture: false,
    address: "c. Тарасівка, вул. Богдана Хмельницького, 11в",
    objectNumber: "7777",
  },
  {
    reason: "Підключення додаткових датчиків",
    technician: "Олександр Гірич",
    date: new Date(),
    withDeparture: true,
    address: "c. Тарасівка, вул. Богдана Хмельницького, 11в",
    objectNumber: "7777",
  },
];

const TechnicianRequests = () => {
  return (
    <Grid
      container
      sx={{
        color: styles.colorSecondary,
      }}
    >
      <Grid item component="section" xs={12}>
        <Typography
          align="center"
          sx={{
            color: styles.colorSecondary,
            fontSize: "26px",
            fontWeight: 600,
          }}
        >
          Мої заявки
        </Typography>
        <FormControl
          sx={{
            marginTop: "1rem",
            backgroundColor: styles.colorSecondary,
            borderRadius: "8px",
            fontWeight: 500,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: styles.colorPrimary, // Default border color
              },
              "&:hover fieldset": {
                borderColor: styles.colorPrimary, // Border color when hovered
              },
              "&.Mui-focused fieldset": {
                borderColor: styles.colorPrimary, // Border color when focused
              },
              "& .MuiInputLabel-root": {
                fontFamily: '"Roboto", sans-serif', // Change the font family of the label
              },
              "& .MuiInputLabel-root.Mui-focused": {
                fontFamily: '"Roboto", sans-serif', // Change the font family when focused
              },
            },
          }}
          fullWidth
        >
          <Select
            labelId="my-select-label"
            id="my-select"
            value={1}
            // onChange={handleChange}
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              borderRadius: "8px",
            }}
            // MenuProps={{
            //   PaperProps: {
            //     sx: {
            //       bgcolor: "#fff",
            //     },
            //   },
            // }}
          >
            <MenuItem
              sx={{
                fontWeight: 500,
                fontSize: "18px",
              }}
              value={1}
            >
              В роботі
            </MenuItem>
            <MenuItem
              sx={{
                fontWeight: 500,
                fontSize: "18px",
              }}
              value={2}
            >
              На перевірці
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        component="section"
        xs={12}
        sx={{
          marginTop: "1rem",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        {techReqs.map((tr, i) => (
          <Link to={"tr" + i} key={i + "tr"} style={{ textDecoration: "none" }}>
            <TechnicianRequestCard techRequest={tr} key={i + "tr"} />
          </Link>
        ))}
      </Grid>
    </Grid>
  );
};

export default TechnicianRequests;
