import { NotificationsActive, Warning } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import styles from "../../styles";

type Props = {
  color: "green" | "red";
};

const EventHistoryItem: FC<Props> = ({ color }) => {
  const makeColorScheme = () => {
    const colors = {
      bgColor: "rgba(55, 182, 40, 0.45)",
      borderColor: "#37B628",
      iconColor: styles.colorSecondary,
    };

    if (color === "green") {
      colors.bgColor = "rgba(55, 182, 40, 0.45)";
      colors.borderColor = "#37B628";
      colors.iconColor = styles.colorSecondary;
    }

    if (color === "red") {
      colors.bgColor = "rgba(237, 55, 55, 0.45)";
      colors.borderColor = "#ED3737";
      colors.iconColor = "#FF0000";
    }

    return colors;
  };

  const colorScheme = makeColorScheme();

  return (
    <Card
      style={{
        backgroundColor: colorScheme.bgColor,
        color: styles.colorSecondary,
        borderRadius: "4px 8px 8px 4px",
        boxShadow: styles.shadow,
      }}
    >
      <Box sx={{ display: "flex", padding: 0 }}>
        <Box
          bgcolor={colorScheme.borderColor}
          sx={{
            width: "12px",
            minHeight: "100%",
            borderRadius: "4px 16px 16px 4px",
            boxShadow: styles.shadow,
          }}
        ></Box>
        <Grid
          container
          flexDirection={"column"}
          //   alignItems="center"
          justifyContent="space-between"
          sx={{ padding: "8px" }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "8px",
              }}
            >
              Test alarm [E130] [g: 2] [z: 1]
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "16px",
              }}
            >
              Description zone 1
            </Typography>
          </Grid>
          <Grid
            item
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              10:47:55
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Об'єкт №7777
            </Typography>
            {color === "green" && (
              <NotificationsActive
                sx={{ fontSize: "18px", color: colorScheme.iconColor }}
              />
            )}
            {color === "red" && (
              <Warning
                sx={{ fontSize: "18px", color: colorScheme.iconColor }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default EventHistoryItem;
