import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "../styles";
import NotificationItem from "../components/NotificationItem/NotificationItem";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import EventHistoryItem from "../components/EventHistoryItem/EventHistoryItem";

const colors: Array<"red" | "green" | "grey"> = [
  "green",
  "red",
  "grey",
  "grey",
  "green",
  "red",
  "green",
  "red",
];

const Notifications = () => {
  const navigate = useNavigate();

  return (
    <Grid container sx={{ color: styles.colorSecondary }}>
      <Grid item xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none", padding: 0 }}
        >
          <Toolbar sx={{ backgroundColor: "transparent", padding: 0 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Сповіщення
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "1rem" }}>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <EventHistoryItem color="red" />
          </Grid>

          <Grid item xs={12}>
            <EventHistoryItem color="green" />
          </Grid>
          {colors.map((c, i) => (
            <Grid item xs={12} key={c + i}>
              <NotificationItem bgColor={c} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <EventHistoryItem color="red" />
          </Grid>

          <Grid item xs={12}>
            <EventHistoryItem color="green" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notifications;
