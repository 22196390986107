import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Icon,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../../styles";
import { Download, Edit, Info, Upload } from "@mui/icons-material";

const UserProfileCard = () => {
  return (
    <Card
      sx={{
        marginTop: "1rem",
        bgcolor: styles.colorSecondary,
        color: styles.colorPrimary,
        borderRadius: "1rem",
        boxShadow: styles.shadow,
      }}
    >
      <CardContent
        sx={{
          padding: "1rem",
        }}
      >
        <Grid container rowGap="16px">
          <Grid item sx={{ display: "flex", gap: "12px" }}>
            {/* <Avatar
              alt="Remy Sharp"
              src={`${process.env.PUBLIC_URL}/assets/profile-default-img.png`}
              sx={{ width: 72, height: 72 }}
            /> */}

            <ImageListItem>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <IconButton
                    sx={{
                      backgroundColor: "#CCD0D9",

                      width: 20,
                      height: 20,
                    }}
                    aria-label={`info about `}
                  >
                    <Edit
                      sx={{ fontSize: "1rem", color: styles.colorPrimary }}
                    />
                  </IconButton>
                }
              >
                <Avatar
                  alt="Remy Sharp"
                  src={`${process.env.PUBLIC_URL}/assets/profile-default-img.png`}
                  sx={{ width: 72, height: 72 }}
                />
              </Badge>

              {/* <ImageListItemBar
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.7)", padding: 0 }}
                    aria-label={`info about `}
                  >
                    <Edit sx={{ fontSize: "24px" }} />
                  </IconButton>
                }
              /> */}
            </ImageListItem>

            <Typography
              component="div"
              fontSize="18px"
              fontWeight={600}
              lineHeight="normal"
              marginBottom="4px"
            >
              Павлюк Роман Васильович
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", gap: "8px", flexDirection: "column" }}
          >
            <Typography
              fontSize="16px"
              fontWeight={400}
              lineHeight="normal"
              marginBottom="4px"
            >
              Дата народження:{" "}
              <Typography
                fontSize="16px"
                fontWeight={600}
                lineHeight="normal"
                component="span"
              >
                23/11/2023
              </Typography>
            </Typography>

            <Typography
              fontSize="16px"
              fontWeight={400}
              lineHeight="normal"
              marginBottom="4px"
            >
              PIN code:{" "}
              <Typography
                fontSize="16px"
                fontWeight={600}
                lineHeight="normal"
                component="span"
              >
                1111
              </Typography>
            </Typography>

            <Typography
              fontSize="16px"
              fontWeight={400}
              lineHeight="normal"
              marginBottom="4px"
            >
              Кодове слово:{" "}
              <Typography
                fontSize="16px"
                fontWeight={600}
                lineHeight="normal"
                component="span"
              >
                TEST
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserProfileCard;
