import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import Home from "./pages/Home";
import CreatingObject from "./pages/CreatingObject";
import Object from "./pages/Object";
import TechnicianRequests from "./pages/TechnicianRequests";
import TechnicianRequest from "./pages/TechnicianRequest";
import Notifications from "./pages/Notifications";
import TrustedPersons from "./pages/TrustedPersons";
import EventsHistory from "./pages/EventsHistory";
import Profile from "./pages/Profile";
import NotFoundPage from "./pages/NotFoundPage";
import CreatingTrustedPerson from "./pages/CreatingTrustedPerson";
import Chat from "./pages/Chat";

export const Root = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="" element={<Navigate to="" replace />} />

        <Route path="objects">
          <Route index element={<Navigate to="" replace />} />
          <Route path=":objectID?">
            <Route index element={<Object />} />
            <Route path="trusted-persons">
              <Route index element={<TrustedPersons />} />
              <Route path="creating" element={<CreatingTrustedPerson />} />
            </Route>
            <Route path="events-history" element={<EventsHistory />} />
          </Route>
        </Route>

        <Route path="creating-object" element={<CreatingObject />} />

        <Route path="tech-reqs">
          <Route index element={<TechnicianRequests />} />
          <Route path=":techReqID?" element={<TechnicianRequest />} />
        </Route>

        <Route path="notifications" element={<Notifications />} />

        <Route path="chat" element={<Chat />} />
        <Route path="profile" element={<Profile />} />
      </Route>

      <Route path="login" element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Router>
);
