import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import styles from "../../styles";
import { ExpandMore } from "@mui/icons-material";
import FormInput from "../FormInput/FormInput";
import CustomButton from "../Button/CustomButton";
import { FiltersGroup } from "../../pages/Home";

type Props = {
  filtersGroup: FiltersGroup;
  changeFiltersGroup: (filtersGroup: FiltersGroup) => void;
};

interface OptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<OptionType>();

const ObjectsFilter: FC<Props> = ({ filtersGroup, changeFiltersGroup }) => {
  const [options, setOptions] = useState<OptionType[]>([
    { title: "Option 1" },
    { title: "Option 2" },
    { title: "Option 3" },
  ]);

  const [value, setValue] = useState<OptionType[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) {
        console.log(file.name);
        // Handle the uploaded file here
      }
    }
  };

  const filterOptions = (options: OptionType[], params: any) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new option if the input value doesn't match any existing option
    if (params.inputValue !== "") {
      filtered.push({
        title: params.inputValue,
      } as OptionType);
    }

    return filtered;
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: Array<OptionType | string>
  ) => {
    const lastItem = newValue[newValue.length - 1];

    if (lastItem && typeof lastItem === "string") {
      changeFiltersGroup({
        ...filtersGroup,
        tags: [...filtersGroup.tags, { title: lastItem }],
      });
    } else if (
      lastItem &&
      typeof lastItem !== "string" &&
      lastItem.inputValue
    ) {
      setValue((prev) => [...prev]);
      changeFiltersGroup({
        ...filtersGroup,
        tags: [...filtersGroup.tags, { title: lastItem.inputValue || "" }],
      });
    } else {
      changeFiltersGroup({
        ...filtersGroup,
        tags: [...(newValue as OptionType[])],
      });
    }
  };

  return (
    <Box
      sx={{
        paddingY: "1rem",
        textAlign: "center",
        borderRadius: "12px",
        backgroundColor: styles.colorSecondary,
      }}
    >
      <Box>
        <Box>
          <Typography fontSize={"18px"} fontWeight={600} lineHeight="normal">
            Фільтри
          </Typography>
        </Box>

        <hr style={{ background: "background: #D9D9D9;" }} />

        <Box
          sx={{
            height: "60vh",
            overflowY: "scroll",
          }}
        >
          <Accordion
            disableGutters
            sx={{
              backgroundColor: styles.colorSecondary,
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "normal",
                padding: "24px",
                boxShadow: styles.shadow,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
              }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Фільтрувати за тегами
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "24px" }}>
              <Autocomplete
                fullWidth
                multiple
                freeSolo
                id="multiple-limit-tags"
                value={filtersGroup.tags}
                options={options}
                onChange={handleChange}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.title
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      sx={{
                        background: styles.bgColor,
                        color: styles.colorSecondary,
                      }}
                      label={option.title}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <FormInput
                    {...params}
                    label="Теги"
                    placeholder="Виберіть теги для фільтрування"
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            sx={{
              backgroundColor: styles.colorSecondary,
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "normal",
                padding: "24px",
                boxShadow: styles.shadow,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
              }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Фільтрувати за ...
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "24px" }}>
              <List>
                <ListItem></ListItem>
              </List>
              <List>
                <ListItem>1</ListItem>
              </List>
              <List>
                <ListItem>1</ListItem>
              </List>
              <List>
                <ListItem>1</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            sx={{
              backgroundColor: styles.colorSecondary,
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "normal",
                padding: "24px",
                boxShadow: styles.shadow,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
              }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Фільтрувати за ...
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "24px" }}>
              <List>
                <ListItem></ListItem>
              </List>
              <List>
                <ListItem>1</ListItem>
              </List>
              <List>
                <ListItem>1</ListItem>
              </List>
              <List>
                <ListItem>1</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      <hr style={{ background: "background: #D9D9D9;" }} />

      <Box sx={{ padding: "16px 12px" }}>
        <CustomButton
          fullWidth
          sx={{ background: styles.bgColor, color: styles.colorSecondary }}
        >
          Застосувати
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ObjectsFilter;
