import {
  Typography,
  Grid,
  Box,
  AppBar,
  IconButton,
  Toolbar,
} from "@mui/material";

import styles from "../styles";
import {
  KeyboardBackspace,
  LocationOn,
  NumbersSharp,
} from "@mui/icons-material";
import FormInput from "../components/FormInput/FormInput";
import TechnicianRequestComment from "../components/TechnicianRequestComment/TechnicianRequestComment";
import { useNavigate } from "react-router-dom";

const TechnicianRequest = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        color: styles.colorSecondary,
      }}
      rowGap="1rem"
    >
      <Grid item component="section" xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none", padding: 0 }}
        >
          <Toolbar sx={{ backgroundColor: "transparent", padding: 0 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Заявка
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item component="section" xs={12}>
        <Box
          sx={{
            padding: "1rem",
            backgroundColor: styles.colorSecondary,
            color: styles.colorPrimary,
            borderRadius: "1rem",
            boxShadow: styles.shadow,

            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "normal",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <NumbersSharp sx={{ mr: 1, fontSize: "18px" }} />
            7777
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: "18px",
              lineHeight: "normal",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <LocationOn sx={{ mr: 1, fontSize: "18px" }} />
            USA
          </Typography>
          <>
            <Typography
              variant="body2"
              sx={{
                color: "rgba(44, 44, 44, 0.70)",
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "normal",
                display: "flex",
                alignItems: "center",
              }}
            >
              Причина виклику техніка
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                lineHeight: "normal",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              Заміна обладнання
            </Typography>
          </>

          <FormInput
            label="Опис"
            placeholder="Ведіть опис об'єкта"
            fullWidth
            sx={{ marginTop: "0.5rem" }}
          />
        </Box>
      </Grid>

      <Grid item component="section" xs={12}>
        <Box
          sx={{
            padding: "1rem",
            backgroundColor: styles.colorSecondary,
            color: styles.colorPrimary,
            borderRadius: "1rem",
            boxShadow: styles.shadow,

            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "1rem", fontWeight: 500, lineHeight: "20px" }}
            >
              Створено
            </Typography>
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              13.05.2024 11:13:55
            </Typography>
          </Typography>

          <Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 500, lineHeight: "20px" }}
              >
                Дата прибуття техніка
              </Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                13.05.2024
              </Typography>
            </Typography>

            <Typography
              variant="body2"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 500, lineHeight: "20px" }}
              >
                Часовий проміжок
              </Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                10:00 - 14:00
              </Typography>
            </Typography>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              marginTop: "0.5rem",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "normal",
                color: "rgba(44, 44, 44, 0.70)",
              }}
            >
              Дата і час взяття заявки в роботу
            </Typography>
            <Typography
              sx={{ fontSize: "18px", lineHeight: "normal", fontWeight: 500 }}
            >
              13.05.2024 11:14:20
            </Typography>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "normal",
                color: "rgba(44, 44, 44, 0.70)",
              }}
            >
              Дата і час відправлення техніка на об’єкт
            </Typography>
            <Typography
              sx={{ fontSize: "18px", lineHeight: "normal", fontWeight: 500 }}
            >
              13.05.2024 11:14:30
            </Typography>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "normal",
                color: "rgba(44, 44, 44, 0.70)",
              }}
            >
              Дата і час завершення робіт техніком
            </Typography>
            <Typography
              sx={{ fontSize: "18px", lineHeight: "normal", fontWeight: 500 }}
            >
              13.05.2024 17:51:34
            </Typography>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "normal",
                color: "rgba(44, 44, 44, 0.70)",
              }}
            >
              Дата і час закриття заявки
            </Typography>
            <Typography
              sx={{ fontSize: "18px", lineHeight: "normal", fontWeight: 500 }}
            >
              13.05.2024 17:51:34
            </Typography>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "20px",
                marginTop: "0.5rem",
              }}
            >
              Вартість робіт
            </Typography>
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              400₴
            </Typography>
          </Typography>

          <Typography
            variant="body2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "20px",
                marginTop: "0.5rem",
              }}
            >
              Технік
            </Typography>
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              Лукяненко Юлія
            </Typography>
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#3E4462",
            color: styles.colorSecondary,
            padding: "1rem",
            borderRadius: "1rem",
            boxShadow: styles.shadow,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: "1rem",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "6px" }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/manager-white-icon.svg`}
                alt="ІСТОРІЯ"
              />
            </span>
            Відповідальна особа
          </Typography>

          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: "1rem",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            Морозов Ярослав Ігорович
          </Typography>

          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: "1rem",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            +380 (98) 968 21 45
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#3E4462",
            padding: "1rem",
            borderRadius: "1rem",
            boxShadow: styles.shadow,
          }}
        >
          <Typography
            sx={{
              color: styles.colorSecondary,
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            Коментарі
          </Typography>

          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <TechnicianRequestComment />
            <TechnicianRequestComment />
            <TechnicianRequestComment />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TechnicianRequest;
