import { Box, Typography, Button } from "@mui/material";
import React from "react";
import styles from "../../styles";
import CustomButton from "../Button/CustomButton";

const AlarmModal = () => {
  return (
    <Box
      sx={{
        borderRadius: "1rem",
        padding: "18px 8px",
        textAlign: "center",
        color: styles.colorSecondary,
        backgroundColor: "#D42F2F",
      }}
    >
      <Typography
        sx={{ fontSize: "24px", lineHeight: "normal", fontWeight: 700 }}
      >
        ТРИВОГА!
      </Typography>
      <Typography
        sx={{
          fontSize: "18px",
          lineHeight: "normal",
          fontWeight: 500,
          marginTop: "4px",
        }}
      >
        Вторгнення
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "normal",
          fontWeight: 500,
          marginTop: "1rem",
        }}
      >
        Об’єкт 1, Зона 4
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "normal",
          fontWeight: 500,
          marginTop: "8px",
        }}
      >
        Вул. Шевченка 5
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <CustomButton
          fullWidth
          variant="contained"
          sx={{
            marginTop: "24px",
            backgroundColor: styles.colorPrimary,
            color: styles.colorSecondary,
          }}
        >
          Викликати групу швидкого реагування
        </CustomButton>
        <CustomButton fullWidth variant="outlined" sx={{}}>
          Зв’язатись з оператором
        </CustomButton>
        <CustomButton fullWidth variant="outlined" sx={{}} onClick={() => {}}>
          Ігнорувати
        </CustomButton>
      </Box>
    </Box>
  );
};

export default AlarmModal;
