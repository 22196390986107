import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../styles";
import CustomButton from "../components/Button/CustomButton";
import ChatMessage from "../components/ChatMessage/ChatMessage";
//import { Upload } from "react-bootstrap-icons";

const greetingMessage = (accecpingFunc: () => void) => {
  return (
    <Box marginTop={"90px"}>
      <Box
        padding="30px 30px"
        sx={{
          background: styles.colorSecondary,
          borderRadius: "16px",
          textAlign: "center",
        }}
      >
        <Typography
          component={"h1"}
          fontSize={"24px"}
          fontWeight={800}
          lineHeight={"normal"}
          marginTop={"2rem"}
          marginBottom={"24px"}
        >
          Вітаємо у чаті!
        </Typography>
        <Typography
          component={"p"}
          fontSize={"18px"}
          fontWeight={600}
          lineHeight={"normal"}
          marginBottom={"64px"}
        >
          Якщо у вас виникли питання, або ви потребуєте допомоги - спілкуйтесь з
          нашими менеджерами тут!
        </Typography>
        <Typography
          component={"p"}
          fontSize={"1rem"}
          fontWeight={400}
          lineHeight={"normal"}
        >
          Ми завжди на зв’язку та готові допомогти вам вирішити проблему!
        </Typography>
      </Box>

      <CustomButton
        fullWidth
        variant="contained"
        sx={{
          background: "#66ACFF",
          color: styles.colorSecondary,
          marginTop: "24px",
        }}
        onClick={accecpingFunc}
      >
        Розпочати розмову
      </CustomButton>
    </Box>
  );
};

export interface TestChatMessage {
  type: "manager" | "me";
  date: Date;
  text: string;
  sender_name: string;
  photo?: string;
}

const testMessages: TestChatMessage[] = [
  {
    type: "me",
    date: new Date(),
    text: "Вітаю, в мене виникло питання.",
    sender_name: "",
  },
  {
    type: "manager",
    date: new Date(),
    text: "Вас вітає компанія А1 Security Group Менеджер Анастасія Чим можу Вам допомогти?",
    sender_name: "Менеджер Анастасія",
  },
  {
    type: "manager",
    date: new Date(),
    text: "Звісно, що саме Вас турбує?",
    sender_name: "Менеджер Анастасія",
  },

  {
    type: "me",
    date: new Date(),
    text: "Дивіться, у мене тут щось таке вилізло незрозуміле",
    sender_name: "",
    photo:
      "https://www.shutterstock.com/shutterstock/videos/20286238/thumb/1.jpg",
  },

  {
    type: "manager",
    date: new Date(),
    text: "Спробуйте натиснути сюди",
    sender_name: "Менеджер Анастасія",
    photo:
      "https://wimsblog.com/wp-content/uploads/2013/05/Martial-Arts-Myths-Death-Touch.jpg",
  },

  {
    type: "manager",
    date: new Date(),
    text: "Чи змогли ми вирішити вашу проблему?",
    sender_name: "Менеджер Анастасія",
    photo: "",
  },

  {
    type: "manager",
    date: new Date(new Date().getTime() + 1000 * 3600 * 48),
    text: "Вітаю! Ще раз хочу уточнити чи змогли ми вирішити вашу проблему?",
    sender_name: "Менеджер Анастасія",
    photo: "",
  },
];

const Chat = () => {
  const [startedChating, setStartedChating] = useState(false);

  return (
    <Box>
      {startedChating ? (
        <>
          <Box
            sx={{ height: "82vh", overflowY: "scroll", marginBottom: "8px" }}
          >
            <Grid container xs={12} rowGap={"8px"}>
              {testMessages.map((msg) => (
                <Grid item xs={12} key={msg.text}>
                  <ChatMessage message={msg} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              position: "fixed",
              bottom: 50,
              left: 0,
              right: 0,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              background: styles.colorSecondary,
              // marginX: "-12px",
              paddingX: "12px",
              paddingY: "8px",
              paddingBottom: "20px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            {/* <IconButton
              sx={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#567DBF",
              }}
            >
              <Upload fontSize={"16px"} color="#567DBF" />
            </IconButton> */}

            <TextField
              variant="outlined"
              placeholder="Повідомлення"
              InputProps={{
                sx: {
                  backgroundColor: "#E5E5E5", // Customize the background color
                  borderRadius: "24px", // Make the corners rounded
                  fontSize: "14px", // Adjust font size if necessaryW
                  fontWeight: 400,

                  "& .MuiInputBase-input": {
                    padding: "12px 10px",
                  },
                },
              }}
              fullWidth
            />
          </Box>
        </>
      ) : (
        greetingMessage(() => setStartedChating(true))
      )}
    </Box>
  );
};

export default Chat;
