import { SxProps } from "@mui/material";

const styles = {
  colorPrimary: "#2C2C2C",
  colorSecondary: "#FFFEF7",
  bgColor: "#567DBF",
  shadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
};

export default styles;
