import { KeyboardBackspace } from "@mui/icons-material";
import { Grid, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventHistoryItem from "../components/EventHistoryItem/EventHistoryItem";
import styles from "../styles";

const EventsHistory = () => {
  const { objectID } = useParams();
  const navigate = useNavigate();

  return (
    <Grid container sx={{ color: styles.colorSecondary }}>
      <Grid item xs={12}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none", padding: 0 }}
        >
          <Toolbar sx={{ backgroundColor: "transparent", padding: 0 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Історія подій
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "1rem" }}>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <EventHistoryItem color="red" />
          </Grid>

          <Grid item xs={12}>
            <EventHistoryItem color="green" />
          </Grid>

          <Grid item xs={12}>
            <EventHistoryItem color="red" />
          </Grid>

          <Grid item xs={12}>
            <EventHistoryItem color="green" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventsHistory;
